<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-14 15:58:34
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-14 19:39:20
--> 
<template>
  <div>
    <div class="title"></div>
    <div class="menu">
      <div
        class="option"
        v-for="(item, index) in menu"
        :key="index"
        :style="`background-image:url('${bgImg}'), linear-gradient(${item.background});`"
        @click="$router.push({ name: item.routerName, query: item.query })"
      >
        <div
          class="icon"
          :style="`background-image:url('${item.icon}');`"
        ></div>
        <div class="grow">
          <div class="name">{{ item.name }}</div>
          <div>
            <span class="num">{{ orderStatusList[item.query.status] }}</span>
            <span class="mini-name">{{ item.miniNotice }}</span>
          </div>
        </div>
        <div class="arrow"></div>
        <div class="notice-num" v-if="orderStatusList[item.query.status]">
          {{ orderStatusList[item.query.status] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bgImg from "../../../assets/images/review/menu_bg.png";
import { orderStatus } from "../../../utils/api/otherWorkOrder";
export default {
  data() {
    return {
      menu: [
        {
          icon: require("@/assets/images/review/menu_review.png"),
          name: "待接单",
          miniNotice: "单还未接单",
          background: "#2cede3, #2d9df1",
          routerName: "ServiceOrderList",
          query: { status: 0 },
        },
        {
          icon: require("@/assets/images/review/menu_price.png"),
          name: "待报价",
          miniNotice: "单还未报价",
          background: "#fdba76, #fd9145",
          routerName: "ServiceOrderList",
          query: { status: 1 },
        },
        {
          icon: require("@/assets/images/review/menu_service.png"),
          name: "待维修",
          miniNotice: "单还未维修",
          background: "#ace683, #31c583",
          routerName: "ServiceOrderList",
          query: { status: 2 },
        },
        {
          icon: require("@/assets/images/review/menu_servicecomplete.png"),
          name: "维修完成",
          miniNotice: "单已经维修完成",
          background: "#6595f8, #3b69cf",
          routerName: "ServiceOrderList",
          query: { status: 3 },
        },
        {
          icon: require("@/assets/images/review/menu_settlement.png"),
          name: "待结算",
          miniNotice: "单待结算",
          background: "#fd9694, #fb6563",
          routerName: "ServiceOrderList",
          query: { status: 4 },
        },
        {
          icon: require("@/assets/images/review/menu_timeout.png"),
          name: "超时",
          miniNotice: "单已超时",
          background: "#f3d291, #d3a06d",
          routerName: "ServiceOrderList",
          query: { status: 5 },
        },
      ],
      bgImg, // 菜单背景图

      orderStatusList: {},
    };
  },

  created() {
    orderStatus().then((res) => {
      let orderStatusList = res.data.data.list;
      let result = {};
      orderStatusList.forEach((item) => {
        result[item.status] = item.number;
      });
      this.orderStatusList = result;
    });
  },

  mounted() {},
};
</script>

<style src="@/assets/css/review/index.css" scoped>
</style>